import React from 'react';
import styled from 'styled-components';

import { About } from '../components/About';
import { FeaturedExamples } from '../components/FeaturedExamples';
import { LinkBox } from '../components/LinkBox';
import { HowItsDifferent } from '../components/HowItsDifferent';
import { OutperformingOthers } from '../components/OutperformingOthers';
import { Examples2 } from '../components/Examples2';
import { Team } from '../components/Team';

export const Home = () => {
    return (
        <MainContent id="home">
            <About />
            <LinkBox />
            <FeaturedExamples />
            <HowItsDifferent />
            <OutperformingOthers />
            <Examples2 />
            <Team />
        </MainContent>
    );
};

const MainContent = styled.main`
    max-width: 1140px;
`;
