import { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { belowOrEqualTo } from '@allenai/varnish';

export const SubHeader = styled.h2`
    color: ${({ theme }) => theme.color.B6};
`;

export const Resposive2Col = styled('div')<{ flop?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing.lg};
    *:first-child {
        order: ${({ flop }) => (flop ? 1 : -1)};
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
        *:first-child {
            order: unset;
        }
    }
`;

export const SmallDivP = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const DivP = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

/**
 * Copied in form Varnish until varnish fixes itself: allenai/varnish#543
 *
 * Use this component inside a top-level <Route /> handler when you'd like
 * the page to be scrolled to the top after a URL change.
 */
const ScrollToTopOnPageChangeImpl = ({ history }: RouteComponentProps) => {
    useEffect(() =>
        history.listen(() => {
            window.scrollTo(0, 0);
        })
    );
    return null;
};

export const ScrollToTopOnPageChange = withRouter(ScrollToTopOnPageChangeImpl);
