import React from 'react';
import styled from 'styled-components';
import { textStyles } from '@allenai/varnish';

export const About = () => {
    return (
        <Container>
            <h1>SatlasPretrain: Understanding the World Through Satellite Imagery</h1>
            <textStyles.Big as="p">
                SatlasPretrain is a large-scale pre-training dataset for tasks that involve
                understanding satellite images. Regularly-updated satellite data is publicly
                available for much of the Earth through sources such as{' '}
                <a href="https://scihub.copernicus.eu/" target="_blank" rel="noreferrer noopener">
                    Sentinel-2
                </a>{' '}
                and{' '}
                <a
                    href="https://naip-usdaonline.hub.arcgis.com/"
                    target="_blank"
                    rel="noreferrer noopener">
                    NAIP
                </a>
                , and can inform numerous applications from tackling illegal deforestation to
                monitoring marine infrastructure. However, developing automatic computer vision
                systems to parse these images requires a huge amount of manual labeling of training
                data. By combining over 30 TB of satellite images with 137 label categories,
                SatlasPretrain serves as an effective pre-training dataset that greatly reduces the
                effort needed to develop robust models for downstream satellite image applications.
            </textStyles.Big>
            <textStyles.Big as="p">
                SatlasPretrain is used in{' '}
                <a href="https://satlas.allen.ai/" target="_blank" rel="noreferrer noopener">
                    Satlas
                </a>
                , a platform for computing open geospatial data using AI and satellite images.
            </textStyles.Big>
        </Container>
    );
};

const Container = styled.div`
    max-width: 946px;
`;
