import React from 'react';
import styled from 'styled-components';
import { Theme } from '@allenai/varnish';

import { Resposive2Col } from './shared';

export const LinkBox = () => {
    return (
        <Resposive2Col>
            <Box>
                <Icon>
                    <PaperIcon />
                </Icon>
                <a
                    href="https://arxiv.org/abs/2211.15660"
                    target="_blank"
                    rel="noreferrer noopener">
                    SatlasPretrain: A Large-Scale Dataset for Remote Sensing Image Understanding
                    (ICCV 2023)
                </a>
            </Box>
            <Box>
                <Icon>
                    <DatasetIcon />
                </Icon>
                <a
                    href="https://github.com/allenai/satlas/blob/main/SatlasPretrain.md"
                    target="_blank"
                    rel="noreferrer noopener">
                    Dataset Documentation and Download
                </a>
            </Box>
        </Resposive2Col>
    );
};

const Icon = styled.div`
    display: flex;
    justify-content: center;
`;

const PaperIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="35"
        viewBox="0 0 28 35"
        role="img"
        aria-hidden="true">
        <title>Document icon</title>
        <path
            d="M27.3,6.3l-6.8-5.8C20.2,0.2,19.7,0,19.1,0h-17C1.6,0,1,0.2,0.6,0.5C0.2,0.8,0,1.3,0,1.8v31.4c0,0.5,0.2,0.9,0.6,1.3 C1,34.8,1.5,35,2.1,35h23.8c0.5,0,1.1-0.2,1.5-0.5s0.6-0.8,0.6-1.3V7.6C27.9,7,27.7,6.6,27.3,6.3z M6,9.5c0-0.7,0.6-1.3,1.3-1.3h9 c0.7,0,1.3,0.6,1.3,1.3v0c0,0.7-0.6,1.3-1.3,1.3h-9C6.6,10.8,6,10.2,6,9.5L6,9.5z M22,25.5c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,24.2,22,24.8,22,25.5L22,25.5z M22,20.2c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,18.8,22,19.4,22,20.2L22,20.2z M22,14.8c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,13.5,22,14.1,22,14.8L22,14.8z"
            fill={Theme.color.N9.toString()}></path>
    </svg>
);

const DatasetIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="32"
        height="32"
        role="img"
        aria-hidden="true">
        <title>Dataset icon</title>
        <path
            d="M 25.001953 0 C 12.439953 -1.1842379e-15 4 4.6530469 4 8.9980469 C 4 13.345047 12.439953 18 25.001953 18 C 37.562953 18 46 13.345047 46 8.9980469 C 46 4.6530469 37.562953 0 25.001953 0 z M 4 13.609375 L 4 19.150391 C 4 23.424391 12.439953 28 25.001953 28 C 37.562953 28 46 23.424391 46 19.150391 L 46 13.609375 C 42.328 17.363375 34.412953 20 25.001953 20 C 15.588953 20 7.673 17.362375 4 13.609375 z M 4 23.910156 L 4 29.195312 C 4 33.468312 12.439953 38 25.001953 38 C 37.562953 38 46 33.467313 46 29.195312 L 46 23.910156 C 42.328 27.662156 34.412953 30 25.001953 30 C 15.588953 30 7.673 27.662156 4 23.910156 z M 4 33.955078 L 4 39.150391 C 4 43.423391 12.439953 48 25.001953 48 C 37.562953 48 46 43.423391 46 39.150391 L 46 33.955078 C 42.328 37.708078 34.412953 40 25.001953 40 C 15.588953 40 7.673 37.708078 4 33.955078 z"
            fill={Theme.color.N9.toString()}></path>
    </svg>
);

const Box = styled.div`
    ${({ theme }) => `
        background: ${theme.color.N3};
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: ${theme.spacing.md};
        margin: ${theme.spacing.xl} 0 ${theme.spacing.xl};
        padding: ${theme.spacing.lg};
        align-items: center;
        font-size: ${theme.typography.textStyles.big.fontSize};
        a, a:hover, a:visited {
            color: ${theme.color.B6};
        }
    `};
`;
