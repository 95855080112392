import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish';
import Menu from 'antd/es/menu';

import { Home } from './pages/Home';
import { AppRoute, AnchorRoute } from './AppRoute';
import logoSrc from './images/logo.svg';

const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
];

const AnchorMenuItems: AnchorRoute[] = [
    {
        label: 'Paper',
        path: 'https://arxiv.org/abs/2211.15660',
    },
    {
        label: 'Download',
        path: 'https://github.com/allenai/satlas/blob/main/SatlasPretrain.md',
    },
    {
        label: 'Back to Satlas',
        path: 'https://satlas.allen.ai/',
    },
];

export const App = (props: RouteComponentProps) => {
    return (
        <BrowserRouter>
            <Route path="/">
                <OverflowLayout bgcolor="white">
                    <Header>
                        <Header.Columns columns="auto 1fr 340px">
                            <Header.Logo label={<Header.AppName>SatlasPretrain</Header.AppName>}>
                                <Logo alt="Satlas Logo" />
                            </Header.Logo>
                            <span />
                            <OverflowHidden>
                                <Header.MenuColumn>
                                    <Menu
                                        defaultSelectedKeys={[props.location.pathname]}
                                        mode="horizontal">
                                        {AnchorMenuItems.map(({ path, label }) => (
                                            <Menu.Item key={path}>
                                                <a href={path}>{label}</a>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </Header.MenuColumn>
                            </OverflowHidden>
                        </Header.Columns>
                    </Header>
                    <Content main>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </Content>
                    <Footer />
                </OverflowLayout>
            </Route>
        </BrowserRouter>
    );
};

const OverflowHidden = styled.div`
    overflow: hidden;
`;

const Logo = styled.img.attrs({ src: logoSrc })`
    height: 50px;
`;

const OverflowLayout = styled(Layout)`
    overflow-x: hidden;
`;
