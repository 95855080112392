import React from 'react';
import styled from 'styled-components';
import { textStyles } from '@allenai/varnish';

import { Grid } from '../components/Grid';
import { SubHeader } from './shared';
import fbSrc from '../images/fb.jpg';
import pwSrc from '../images/pw.jpg';
import rgSrc from '../images/rg.jpg';
import jfSrc from '../images/jf.jpg';
import akSrc from '../images/ak.jpg';

export const Team = () => {
    return (
        <React.Fragment>
            <SubHeader>Team</SubHeader>
            <Grid variant="4col" gap="xs" ragged={true}>
                <div>
                    <PersonImg src={fbSrc} alt="Favyen Bastani headshot" />
                    <textStyles.Big as="p">
                        <strong>Favyen Bastani</strong>
                    </textStyles.Big>
                </div>
                <div>
                    <PersonImg src={pwSrc} alt="Piper Wolters headshot" />
                    <textStyles.Big as="p">
                        <strong>Piper Wolters</strong>
                    </textStyles.Big>
                </div>
                <div>
                    <PersonImg src={rgSrc} alt="Ritwik Gupta headshot" />
                    <textStyles.Big as="p">
                        <strong>Ritwik Gupta</strong>
                    </textStyles.Big>
                </div>
                <div>
                    <PersonImg src={jfSrc} alt="Joseph Ferdinando< headshot" />
                    <textStyles.Big as="p">
                        <strong>Joseph Ferdinando</strong>
                    </textStyles.Big>
                </div>
                <div>
                    <PersonImg src={akSrc} alt="Ani Kembhavi headshot" />
                    <textStyles.Big as="p">
                        <strong>Ani Kembhavi</strong>
                    </textStyles.Big>
                </div>
            </Grid>
        </React.Fragment>
    );
};

const PersonImg = styled.img`
    width: 100%;
`;
