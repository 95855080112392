import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
    inSrc: string;
    inAlt: string;
    outSrc: string;
    outAlt: string;
    width?: string;
    height?: string;
    speed?: string;
}
export const CrossFadeImage = ({ inSrc, inAlt, outSrc, outAlt, width, height, speed }: Props) => {
    return (
        <CrossFade width={width} height={height} speed={speed}>
            <FadeInImage src={inSrc} alt={inAlt} />
            <FadeOutImage src={outSrc} alt={outAlt} />
        </CrossFade>
    );
};

const FadeOutImage = styled.img``;

const FadeInImage = styled.img``;

const FadeFrames = keyframes`
        0% {
            opacity: 0;
        }
        30% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
`;

const CrossFade = styled.div<{ width?: string; height?: string; speed?: string }>`
    width: ${({ width }) => width || '400px'};
    height: ${({ height }) => height || 'unset'};
    max-height: 100vw;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    ${FadeOutImage}, ${FadeInImage} {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: opacity ${({ speed }) => speed || '3s'} ease-in-out;
    }
    ${FadeOutImage} {
        animation-name: ${FadeFrames};
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: ${({ speed }) => speed || '3s'};
        animation-direction: alternate;
    }
`;
