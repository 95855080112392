import React from 'react';
import styled from 'styled-components';
import { belowOrEqualTo, Theme } from '@allenai/varnish';

/**
 * NOTE: this was copied from ai2-web/ui/lib/components/containers/Grid
 * We should move this to tugboat/varnish as part of the PP refactor.
 */
export type GridVariant = '2col' | '3col' | '4col';
export type GapSize = 'xs' | 'sm' | 'md' | 'lg';
interface Props {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    variant?: GridVariant;
    initialGridTemplateColumns?: string;
    gap?: GapSize;
    /* If true, the grid doesn't adjust it's displays on smaller screens. */
    nonResponsive?: boolean;
    alignItems?: string;
    /* Ragged specifies how we change the number of columns based on breakpoints.
     * FALSE = ragged (default), as we make the window narrower:
     * 4col
     *   X X X X |
     * becomes
     *   X X |
     *   X X |
     * and 3col:
     *   X X X |
     * becomes
     *   X |
     *   X |
     *   X |
     *
     * TRUE == Ragged, as we make the window narrower:
     * 4col
     *   X X X X |
     * becomes
     *   X X X |
     *   X     |
     * and 3col:
     *   X X X |
     * becomes
     *   X X |
     *   X   |
     */
    ragged?: boolean;
}

/**
 * A grid that defaults to 2 equal columns
 */
export const Grid = ({
    className,
    children,
    variant,
    initialGridTemplateColumns,
    gap = 'md',
    nonResponsive,
    alignItems,
    ragged,
}: Props) => {
    switch (variant) {
        case '4col':
            return (
                <Grid4ColWrapper
                    className={className}
                    initialGridTemplateColumns={initialGridTemplateColumns}
                    gap={gap}
                    ragged={ragged}
                    nonResponsive={nonResponsive}
                    alignItems={alignItems}>
                    {children}
                </Grid4ColWrapper>
            );
        case '3col':
            return (
                <Grid3ColWrapper
                    className={className}
                    initialGridTemplateColumns={initialGridTemplateColumns}
                    gap={gap}
                    ragged={ragged}
                    nonResponsive={nonResponsive}
                    alignItems={alignItems}>
                    {children}
                </Grid3ColWrapper>
            );
        case '2col':
        default:
            return (
                <Grid2ColWrapper
                    className={className}
                    initialGridTemplateColumns={initialGridTemplateColumns}
                    gap={gap}
                    ragged={ragged}
                    nonResponsive={nonResponsive}
                    alignItems={alignItems}>
                    {children}
                </Grid2ColWrapper>
            );
    }
};

const GridWrapper = styled.div<{
    initialGridTemplateColumns?: string;
    gap: GapSize;
    ragged?: boolean;
    nonResponsive?: boolean;
    alignItems?: string;
}>`
    display: grid;
    grid-auto-rows: 1fr;
    ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : null)}
`;

const gapSizeMap = (theme: typeof Theme) => {
    const defaultMobileGap = theme.spacing.lg;
    // until there's a use case for going outside of the standard medium-sized gap, we'll use the default for all 4 col
    const default4col = {
        desktop: `${theme.spacing.xl2} ${theme.spacing.xl}`,
        mobile: defaultMobileGap,
    };
    return {
        '2col': {
            xs: {
                desktop: theme.spacing.sm,
                mobile: theme.spacing.xxs,
            },
            sm: {
                desktop: theme.spacing.xl,
                mobile: theme.spacing.md,
            },
            md: {
                desktop: `${theme.spacing.xl3} ${theme.spacing.xl3}`,
                mobile: defaultMobileGap,
            },
            lg: {
                desktop: `${theme.spacing.xl3} ${theme.spacing.xl4}`,
                mobile: defaultMobileGap,
            },
        },
        '3col': {
            xs: {
                desktop: theme.spacing.sm,
                mobile: theme.spacing.xs,
            },
            sm: {
                desktop: theme.spacing.sm,
                mobile: theme.spacing.xs,
            },
            md: {
                desktop: `${theme.spacing.xl2} ${theme.spacing.xl2}`,
                mobile: defaultMobileGap,
            },
            lg: {
                desktop: `${theme.spacing.xl2} ${theme.spacing.xl4}`,
                mobile: defaultMobileGap,
            },
        },
        '4col': {
            xs: default4col,
            sm: default4col,
            md: default4col,
            lg: default4col,
        },
    };
};

const Grid4ColWrapper = styled(GridWrapper)`
    grid-template-columns: ${({ initialGridTemplateColumns }) =>
        initialGridTemplateColumns || '1fr 1fr 1fr 1fr'};
    gap: ${({ theme, gap }) => gapSizeMap(theme)['4col'][gap].desktop.toString()};
    ${({ nonResponsive, theme, gap, ragged }) =>
        !nonResponsive
            ? `
        @media ${belowOrEqualTo(theme.breakpoints.lg)} {
            grid-template-columns: ${ragged ? '1fr 1fr 1fr' : '1fr 1fr'};
        }
        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            grid-template-columns: 1fr 1fr;
        }
        @media ${belowOrEqualTo(theme.breakpoints.sm)} {
            grid-template-columns: 1fr;
            gap: ${gapSizeMap(theme)['4col'][gap].mobile};
            grid-auto-rows: auto;
        }
    `
            : ''}
`;

const Grid3ColWrapper = styled(GridWrapper)`
    grid-template-columns: ${({ initialGridTemplateColumns }) =>
        initialGridTemplateColumns || '1fr 1fr 1fr'};
    gap: ${({ theme, gap }) => gapSizeMap(theme)['3col'][gap].desktop.toString()};
    ${({ nonResponsive, theme, gap, ragged }) =>
        !nonResponsive
            ? `
        @media ${belowOrEqualTo(theme.breakpoints.lg)} {
            grid-template-columns: ${ragged ? '1fr 1fr' : '1fr'};
            gap: ${gapSizeMap(theme)['3col'][gap].mobile};
        }
        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
        }
    `
            : ''}
`;

const Grid2ColWrapper = styled(GridWrapper)`
    grid-template-columns: ${({ initialGridTemplateColumns }) =>
        initialGridTemplateColumns || '1fr 1fr'};
    gap: ${({ theme, gap }) => gapSizeMap(theme)['2col'][gap].desktop.toString()};
    ${({ nonResponsive, theme, gap }) =>
        !nonResponsive
            ? `
        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            /* The use of minmax here prevents a "grid blowout:
            See: https://css-tricks.com/preventing-a-grid-blowout/ */
            grid-template-columns: minmax(0, 1fr);
            gap: ${gapSizeMap(theme)['3col'][gap].mobile};
            grid-auto-rows: auto;
        }
    `
            : ''}
`;
