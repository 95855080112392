import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

import { CrossFadeImage } from '../components/CrossFadeImage';
import waterSrc from '../images/water_event_compressed.jpg';
import waterWoSrc from '../images/water_event_wo_compressed.jpg';
import mineshaftSrc from '../images/mineshaft_event_compressed.jpg';
import mineshaftWoSrc from '../images/mineshaft_event_wo_compressed.jpg';
import marineSrc from '../images/marine_event_compressed.jpg';
import marineWoSrc from '../images/marine_event_wo_compressed.jpg';
import waterIconSrc from '../images/waterways.svg';
import mineIconSrc from '../images/mine.svg';
import offshoreIconSrc from '../images/offshore.svg';

export const FeaturedExamples = () => {
    const [gutterWidth, setGutterWidth] = useState(150);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setGutterWidth(
                Math.max(
                    44,
                    // these numbers are margin, label1, label2, label3, margin
                    (event[0].contentBoxSize[0].inlineSize - (20 + 137 + 77 + 127 + 20)) / 2
                )
            );
        });
        const tabsDiv = document.getElementById('tabsDiv');
        if (tabsDiv) {
            resizeObserver.observe(tabsDiv);
        }
    });

    const tabInfo = [
        {
            label: (
                <span>
                    <ToggleOnBig hideOnBig={true}>
                        <img src={waterIconSrc} alt="Natural waterways" />
                    </ToggleOnBig>
                    <ToggleOnBig hideOnBig={false}>Natural waterways</ToggleOnBig>
                </span>
            ),
            key: 'Natural waterways',
            children: (
                <CrossFadeImage
                    outSrc={waterSrc}
                    outAlt="Natural waterways"
                    inSrc={waterWoSrc}
                    inAlt="Natural waterways with Satlas overlay"
                    width="100%"
                    height="600px"
                    speed="4s"
                />
            ),
        },
        {
            label: (
                <span>
                    <ToggleOnBig hideOnBig={true}>
                        <img src={mineIconSrc} alt="Mine shafts" />
                    </ToggleOnBig>
                    <ToggleOnBig hideOnBig={false}>Mine Shafts</ToggleOnBig>
                </span>
            ),
            key: 'Mine Shafts',
            children: (
                <CrossFadeImage
                    outSrc={mineshaftSrc}
                    outAlt="Mine shafts"
                    inSrc={mineshaftWoSrc}
                    inAlt="Mine shafts with Satlas overlay"
                    width="100%"
                    height="600px"
                    speed="4s"
                />
            ),
        },
        {
            label: (
                <span>
                    <ToggleOnBig hideOnBig={true}>
                        <img src={offshoreIconSrc} alt="Offshore Objects" />
                    </ToggleOnBig>
                    <ToggleOnBig hideOnBig={false}>Offshore Objects</ToggleOnBig>
                </span>
            ),
            key: 'Offshore Objects',
            children: (
                <CrossFadeImage
                    outSrc={marineSrc}
                    outAlt="Offshore wind turbines & vessels"
                    inSrc={marineWoSrc}
                    inAlt="Offshore wind turbines & vessels with Satlas overlay"
                    width="100%"
                    height="600px"
                    speed="4s"
                />
            ),
        },
    ];

    return (
        <div id="tabsDiv">
            <Tabs
                items={tabInfo}
                destroyInactiveTabPane={true}
                centered
                tabPosition="bottom"
                tabBarGutter={gutterWidth}
            />
        </div>
    );
};

const ToggleOnBig = styled.span<{ hideOnBig: boolean }>`
    display: ${({ hideOnBig }) => (hideOnBig ? 'none' : 'unset')};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.xs)} {
        display: ${({ hideOnBig }) => (!hideOnBig ? 'none' : 'unset')};
    }
`;
