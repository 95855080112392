import { call, CallResult } from 'wasm-imagemagick';

const imKey = 'im.png';
const gtKey = 'gt.png';
const metaKey = 'meta.json';

export interface ExampleFetchData {
    errors: string[];
    img1Src: string;
    img2Src: string;
    lat: number;
    lon: number;
}
export const exampleFetch = async (
    baseUrl: string,
    annotationType?: string
): Promise<ExampleFetchData> => {
    const ret: ExampleFetchData = {
        errors: [],
        img1Src: '',
        img2Src: '',
        lat: 0,
        lon: 0,
    };

    const imRes = await fetch(baseUrl + imKey);
    const gtRes = await fetch(baseUrl + gtKey);
    const metaRes = await fetch(baseUrl + metaKey);
    if (imRes.ok) {
        ret.img1Src = baseUrl + imKey;
        ret.img2Src = baseUrl + imKey;
    } else {
        // we require that im exist
        ret.errors.push('base image does not exist');
    }
    if (gtRes.ok) {
        ret.img2Src = baseUrl + gtKey;
        // segmentation results are red on black, here we are converting the black to transparent
        if (annotationType === 'Segmentation') {
            const pdfContent = new Uint8Array(await gtRes.arrayBuffer());
            const pdfObj = { name: 'srcFile.png', content: pdfContent };
            const command = ['convert', 'srcFile.png', '-transparent', 'black', 'out.png'];
            const result: CallResult = await call([pdfObj], command);
            console.log(result);
            if (result.exitCode !== 0) {
                ret.errors.push('There was an error: ' + result.stderr.join('\n'));
            }
            // response can be multiple files (example split) here we know we just have one
            const outputImage = result.outputFiles[0];
            ret.img2Src = URL.createObjectURL(outputImage.blob);
        }
    }
    const meta = await metaRes.json();
    ret.lat = meta.lat;
    ret.lon = meta.lon;
    if (!ret.lat || !ret.lon) {
        // we require that lat and lon exist
        ret.errors.push('lat long not found');
    }
    return ret;
};
