export interface Match {
    annotationType?: string;
    category?: string;
    combinedLabel?: string;
}

export interface OriginalData {
    annotationType: string;
    category: string;
    label: string;
    exampleNumber: number;
    folderUrl: string;
}

export interface Data extends OriginalData {
    combinedLabel: string;
}

export const filterData = (match?: Match): Data[] => {
    if (!match) {
        return data;
    }
    const filtered = data.filter((d) => {
        if (match.annotationType && !insensitiveFilter(match.annotationType, d.annotationType)) {
            return false;
        }
        if (match.category && !insensitiveFilter(match.category, d.category)) {
            return false;
        }
        if (match.combinedLabel && !insensitiveFilter(match.combinedLabel, d.combinedLabel)) {
            return false;
        }
        return true;
    });
    return filtered;
};

const insensitiveFilter = (inputValue: string, actual: string | number) =>
    actual.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

export const uniqueAnnotationTypes = (data: Data[]) => {
    return unique(data.map((f) => f.annotationType)).sort((a, b) => a.localeCompare(b));
};

export const uniqueCategories = (data: Data[]) => {
    return unique(data.map((f) => f.category)).sort((a, b) => a.localeCompare(b));
};

export const uniqueCombinedLabels = (data: Data[]) => {
    return unique(data.map((f) => f.combinedLabel)).sort((a, b) => a.localeCompare(b));
};

const unique = (arr: string[]): string[] => {
    return [...new Set(arr)].filter((v) => v.length);
};

export const getRandomExample = (selectedAnnotationTypes: string[]): Data | undefined => {
    const filteredData = data.filter((d) => selectedAnnotationTypes.includes(d.annotationType));
    return filteredData.length
        ? filteredData[Math.floor(Math.random() * filteredData.length)]
        : undefined;
};

const originalData: OriginalData[] = [
    {
        annotationType: 'Point',
        category: 'Aerialway Pylon',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aerialway_pylon/aerialway_pylon_44_',
    },
    {
        annotationType: 'Point',
        category: 'Aerialway Pylon',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aerialway_pylon/aerialway_pylon_36_',
    },
    {
        annotationType: 'Point',
        category: 'Aerialway Pylon',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aerialway_pylon/aerialway_pylon_31_',
    },
    {
        annotationType: 'Point',
        category: 'Aerialway Pylon',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aerialway_pylon/aerialway_pylon_12_',
    },
    {
        annotationType: 'Point',
        category: 'Airplane',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airplane/airplane_18_',
    },
    {
        annotationType: 'Point',
        category: 'Airplane',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airplane/airplane_0_',
    },
    {
        annotationType: 'Point',
        category: 'Airplane',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airplane/airplane_1_',
    },
    {
        annotationType: 'Point',
        category: 'Airplane',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airplane/airplane_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport/airport_23_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport/airport_121_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport/airport_101_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport/airport_52_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Apron',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_apron/airport_apron_10_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Apron',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_apron/airport_apron_36_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Apron',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_apron/airport_apron_17_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Apron',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_apron/airport_apron_4_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Hangar',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_hangar/airport_hangar_19_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Hangar',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_hangar/airport_hangar_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Hangar',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_hangar/airport_hangar_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Hangar',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_hangar/airport_hangar_7_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Runway',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_runway/airport_runway_13_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Runway',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_runway/airport_runway_6_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Runway',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_runway/airport_runway_17_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Runway',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_runway/airport_runway_1_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Taxiway',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_taxiway/airport_taxiway_2_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Taxiway',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_taxiway/airport_taxiway_24_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Taxiway',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_taxiway/airport_taxiway_4_',
    },
    {
        annotationType: 'Polyline',
        category: 'Airport - Taxiway',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_taxiway/airport_taxiway_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Terminal',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_terminal/airport_terminal_15_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Terminal',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_terminal/airport_terminal_16_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Terminal',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_terminal/airport_terminal_26_',
    },
    {
        annotationType: 'Polygon',
        category: 'Airport - Terminal',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/airport_terminal/airport_terminal_18_',
    },
    {
        annotationType: 'Polygon',
        category: 'Aquafarm',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aquafarm/aquafarm_19_',
    },
    {
        annotationType: 'Polygon',
        category: 'Aquafarm',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aquafarm/aquafarm_18_',
    },
    {
        annotationType: 'Polygon',
        category: 'Aquafarm',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aquafarm/aquafarm_14_',
    },
    {
        annotationType: 'Polygon',
        category: 'Aquafarm',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/aquafarm/aquafarm_36_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop Type',
        label: 'Asparagus',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/asparagus/asparagus_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop Type',
        label: 'Asparagus',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/asparagus/asparagus_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop Type',
        label: 'Asparagus',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/asparagus/asparagus_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop Type',
        label: 'Asparagus',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/asparagus/asparagus_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Bare',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/bare/bare_7_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Bare',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/bare/bare_28_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Bare',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/bare/bare_30_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Bare',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/bare/bare_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Barley',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/barley/barley_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Barley',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/barley/barley_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Barley',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/barley/barley_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Barley',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/barley/barley_1_',
    },
    {
        annotationType: 'Polygon',
        category: 'Building',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/building/building_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Building',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/building/building_10_',
    },
    {
        annotationType: 'Polygon',
        category: 'Building',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/building/building_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Building',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/building/building_13_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Burned Area',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/burned/burned_8_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Burned Area',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/burned/burned_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Burned Area',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/burned/burned_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Burned Area',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/burned/burned_10_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Cassava',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cassava/cassava_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Cassava',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cassava/cassava_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Cassava',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cassava/cassava_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Cassava',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cassava/cassava_3_',
    },
    {
        annotationType: 'Point',
        category: 'Chimney',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/chimney/chimney_6_',
    },
    {
        annotationType: 'Point',
        category: 'Chimney',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/chimney/chimney_3_',
    },
    {
        annotationType: 'Point',
        category: 'Chimney',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/chimney/chimney_8_',
    },
    {
        annotationType: 'Point',
        category: 'Chimney',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/chimney/chimney_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Cloud',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cloud/cloud_22_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Cloud',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cloud/cloud_11_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Cloud',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cloud/cloud_44_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Cloud',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/cloud/cloud_30_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Coffee',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/coffee/coffee_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Coffee',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/coffee/coffee_8_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Coffee',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/coffee/coffee_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Coffee',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/coffee/coffee_10_',
    },
    {
        annotationType: 'Point',
        category: 'Communications Tower',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/communications_tower/communications_tower_5_',
    },
    {
        annotationType: 'Point',
        category: 'Communications Tower',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/communications_tower/communications_tower_3_',
    },
    {
        annotationType: 'Point',
        category: 'Communications Tower',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/communications_tower/communications_tower_7_',
    },
    {
        annotationType: 'Point',
        category: 'Communications Tower',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/communications_tower/communications_tower_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Corn',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/corn/corn_12_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Corn',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/corn/corn_14_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Corn',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/corn/corn_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Corn',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/corn/corn_22_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Crop',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop/crop_8_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Crop',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop/crop_11_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Crop',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop/crop_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Crop',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop/crop_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Crop Field',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop_field/crop_field_134_',
    },
    {
        annotationType: 'Polygon',
        category: 'Crop Field',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop_field/crop_field_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Crop Field',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop_field/crop_field_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Crop Field',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/crop_field/crop_field_44_',
    },
    {
        annotationType: 'Polygon',
        category: 'Dam',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dam/dam_10_',
    },
    {
        annotationType: 'Polygon',
        category: 'Dam',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dam/dam_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Dam',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dam/dam_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Dam',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dam/dam_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Elevation and Bathymetry',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dem/dem_27_',
    },
    {
        annotationType: 'Regression',
        category: 'Elevation and Bathymetry',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dem/dem_23_',
    },
    {
        annotationType: 'Regression',
        category: 'Elevation and Bathymetry',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dem/dem_63_',
    },
    {
        annotationType: 'Regression',
        category: 'Elevation and Bathymetry',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/dem/dem_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Developed',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/developed/developed_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Developed',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/developed/developed_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Developed',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/developed/developed_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Developed',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/developed/developed_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Fire Retardant',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fire_retardant/fire_retardant_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Fire Retardant',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fire_retardant/fire_retardant_9_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Fire Retardant',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fire_retardant/fire_retardant_12_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Wildfire',
        label: 'Fire Retardant',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fire_retardant/fire_retardant_7_',
    },
    {
        annotationType: 'Point',
        category: 'Flagpole',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flagpole/flagpole_3_',
    },
    {
        annotationType: 'Point',
        category: 'Flagpole',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flagpole/flagpole_12_',
    },
    {
        annotationType: 'Point',
        category: 'Flagpole',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flagpole/flagpole_14_',
    },
    {
        annotationType: 'Point',
        category: 'Flagpole',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flagpole/flagpole_18_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Flood',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flood/flood_119_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Flood',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flood/flood_35_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Flood',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flood/flood_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Flood',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/flood/flood_1_',
    },
    {
        annotationType: 'Point',
        category: 'Fountain',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fountain/fountain_0_',
    },
    {
        annotationType: 'Point',
        category: 'Fountain',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fountain/fountain_4_',
    },
    {
        annotationType: 'Point',
        category: 'Fountain',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fountain/fountain_8_',
    },
    {
        annotationType: 'Point',
        category: 'Fountain',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/fountain/fountain_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Gas Station',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/gas_station/gas_station_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Gas Station',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/gas_station/gas_station_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Gas Station',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/gas_station/gas_station_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Gas Station',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/gas_station/gas_station_14_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Grape',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grape/grape_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Grape',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grape/grape_10_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Grape',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grape/grape_7_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Grape',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grape/grape_18_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Grass',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grass/grass_10_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Grass',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grass/grass_14_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Grass',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grass/grass_15_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Grass',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/grass/grass_1_',
    },
    {
        annotationType: 'Point',
        category: 'Helipad',
        label: 'All',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/helipad/helipad_2_',
    },
    {
        annotationType: 'Point',
        category: 'Helipad',
        label: 'All',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/helipad/helipad_11_',
    },
    {
        annotationType: 'Point',
        category: 'Helipad',
        label: 'All',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/helipad/helipad_19_',
    },
    {
        annotationType: 'Point',
        category: 'Helipad',
        label: 'All',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/helipad/helipad_7_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Hop',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/hop/hop_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Hop',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/hop/hop_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Hop',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/hop/hop_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Hop',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/hop/hop_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Landfill',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/landfill/landfill_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Landfill',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/landfill/landfill_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Landfill',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/landfill/landfill_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Landfill',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/landfill/landfill_4_',
    },
    {
        annotationType: 'Point',
        category: 'Lighthouse',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lighthouse/lighthouse_10_',
    },
    {
        annotationType: 'Point',
        category: 'Lighthouse',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lighthouse/lighthouse_1_',
    },
    {
        annotationType: 'Point',
        category: 'Lighthouse',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lighthouse/lighthouse_3_',
    },
    {
        annotationType: 'Point',
        category: 'Lighthouse',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lighthouse/lighthouse_0_',
    },
    {
        annotationType: 'Polygon',
        category: 'Lock',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lock/lock_13_',
    },
    {
        annotationType: 'Polygon',
        category: 'Lock',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lock/lock_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Lock',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lock/lock_4_',
    },
    {
        annotationType: 'Polygon',
        category: 'Lock',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/lock/lock_6_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Mangroves',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mangroves/mangroves_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Mangroves',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mangroves/mangroves_6_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Mangroves',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mangroves/mangroves_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Mangroves',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mangroves/mangroves_5_',
    },
    {
        annotationType: 'Point',
        category: 'Mineshaft',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mineshaft/mineshaft_0_',
    },
    {
        annotationType: 'Point',
        category: 'Mineshaft',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mineshaft/mineshaft_5_',
    },
    {
        annotationType: 'Point',
        category: 'Mineshaft',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mineshaft/mineshaft_2_',
    },
    {
        annotationType: 'Point',
        category: 'Mineshaft',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/mineshaft/mineshaft_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Moss',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/moss/moss_26_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Moss',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/moss/moss_7_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Moss',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/moss/moss_21_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Moss',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/moss/moss_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Park',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park/park_4_',
    },
    {
        annotationType: 'Polygon',
        category: 'Park',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park/park_15_',
    },
    {
        annotationType: 'Polygon',
        category: 'Park',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park/park_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Park',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park/park_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Garage',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_garage/parking_garage_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Garage',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_garage/parking_garage_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Garage',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_garage/parking_garage_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Garage',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_garage/parking_garage_14_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Lot',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot/parking_lot_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Lot',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot/parking_lot_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Lot',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot/parking_lot_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Parking Lot',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot/parking_lot_5_',
    },
    {
        annotationType: 'Property',
        category: 'Parking Lot - Capacity',
        label: '30',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot_capacity_30/parking_lot_capacity_30_11_',
    },
    {
        annotationType: 'Property',
        category: 'Parking Lot - Capacity',
        label: '84',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot_capacity_84/parking_lot_capacity_84_23_',
    },
    {
        annotationType: 'Property',
        category: 'Parking Lot - Capacity',
        label: '200',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot_capacity_200/parking_lot_capacity_200_6_',
    },
    {
        annotationType: 'Property',
        category: 'Parking Lot - Capacity',
        label: '1252',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/parking_lot_capacity_1252/parking_lot_capacity_1252_64_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Cemetery',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_cemetery/park_park_type_cemetery_8_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Cemetery',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_cemetery/park_park_type_cemetery_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Cemetery',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_cemetery/park_park_type_cemetery_5_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Cemetery',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_cemetery/park_park_type_cemetery_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Golf Course',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_golf_course/park_park_type_golf_course_5_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Golf Course',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_golf_course/park_park_type_golf_course_12_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Golf Course',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_golf_course/park_park_type_golf_course_7_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Golf Course',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_golf_course/park_park_type_golf_course_9_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'City Park',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_park/park_park_type_park_14_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'City Park',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_park/park_park_type_park_35_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'City Park',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_park/park_park_type_park_21_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'City Park',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_park/park_park_type_park_12_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Sports Pitch',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_pitch/park_park_type_pitch_3_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Sports Pitch',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_pitch/park_park_type_pitch_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Sports Pitch',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_pitch/park_park_type_pitch_12_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Type',
        label: 'Sports Pitch',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_park_type_pitch/park_park_type_pitch_7_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'American Football',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_american_football/park_sport_american_football_9_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'American Football',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_american_football/park_sport_american_football_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'American Football',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_american_football/park_sport_american_football_13_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'American Football',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_american_football/park_sport_american_football_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Badminton',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_badminton/park_sport_badminton_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Badminton',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_badminton/park_sport_badminton_1_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Badminton',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_badminton/park_sport_badminton_5_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Badminton',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_badminton/park_sport_badminton_9_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Baseball',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_baseball/park_sport_baseball_6_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Baseball',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_baseball/park_sport_baseball_8_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Baseball',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_baseball/park_sport_baseball_7_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Baseball',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_baseball/park_sport_baseball_19_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Basketball',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_basketball/park_sport_basketball_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Basketball',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_basketball/park_sport_basketball_8_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Basketball',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_basketball/park_sport_basketball_12_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Basketball',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_basketball/park_sport_basketball_1_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Cricket',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_cricket/park_sport_cricket_21_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Cricket',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_cricket/park_sport_cricket_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Cricket',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_cricket/park_sport_cricket_0_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Cricket',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_cricket/park_sport_cricket_12_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Rugby',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_rugby/park_sport_rugby_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Rugby',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_rugby/park_sport_rugby_3_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Rugby',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_rugby/park_sport_rugby_7_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Rugby',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_rugby/park_sport_rugby_9_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Soccer',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_soccer/park_sport_soccer_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Soccer',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_soccer/park_sport_soccer_0_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Soccer',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_soccer/park_sport_soccer_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Soccer',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_soccer/park_sport_soccer_6_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Tennis',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_tennis/park_sport_tennis_1_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Tennis',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_tennis/park_sport_tennis_4_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Tennis',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_tennis/park_sport_tennis_2_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Tennis',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_tennis/park_sport_tennis_5_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Volleyball',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_volleyball/park_sport_volleyball_9_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Volleyball',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_volleyball/park_sport_volleyball_3_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Volleyball',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_volleyball/park_sport_volleyball_6_',
    },
    {
        annotationType: 'Property',
        category: 'Park - Sport',
        label: 'Volleyball',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/park_sport_volleyball/park_sport_volleyball_7_',
    },
    {
        annotationType: 'Point',
        category: 'Petroleum Well',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/petroleum_well/petroleum_well_7_',
    },
    {
        annotationType: 'Point',
        category: 'Petroleum Well',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/petroleum_well/petroleum_well_4_',
    },
    {
        annotationType: 'Point',
        category: 'Petroleum Well',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/petroleum_well/petroleum_well_11_',
    },
    {
        annotationType: 'Point',
        category: 'Petroleum Well',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/petroleum_well/petroleum_well_1_',
    },
    {
        annotationType: 'Polygon',
        category: 'Pier',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/pier/pier_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Pier',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/pier/pier_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Pier',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/pier/pier_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Pier',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/pier/pier_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Potato',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/potato/potato_6_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Potato',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/potato/potato_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Potato',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/potato/potato_15_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Potato',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/potato/potato_1_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Plant',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant/power_plant_10_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Plant',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant/power_plant_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Plant',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant/power_plant_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Plant',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant/power_plant_4_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Coal',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_coal/power_plant_plant_type_coal_0_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Coal',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_coal/power_plant_plant_type_coal_9_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Coal',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_coal/power_plant_plant_type_coal_26_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Coal',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_coal/power_plant_plant_type_coal_15_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Gas',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_gas/power_plant_plant_type_gas_0_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Gas',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_gas/power_plant_plant_type_gas_11_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Gas',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_gas/power_plant_plant_type_gas_8_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Gas',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_gas/power_plant_plant_type_gas_4_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Nuclear',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_nuclear/power_plant_plant_type_nuclear_1_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Nuclear',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_nuclear/power_plant_plant_type_nuclear_0_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Oil',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_oil/power_plant_plant_type_oil_0_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Oil',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_oil/power_plant_plant_type_oil_1_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Oil',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_oil/power_plant_plant_type_oil_6_',
    },
    {
        annotationType: 'Property',
        category: 'Power Plant - Type',
        label: 'Oil',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_plant_plant_type_oil/power_plant_plant_type_oil_2_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Substation',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_substation/power_substation_8_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Substation',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_substation/power_substation_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Substation',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_substation/power_substation_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Power Substation',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_substation/power_substation_4_',
    },
    {
        annotationType: 'Point',
        category: 'Transmission Tower',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_tower/power_tower_1_',
    },
    {
        annotationType: 'Point',
        category: 'Transmission Tower',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_tower/power_tower_0_',
    },
    {
        annotationType: 'Point',
        category: 'Transmission Tower',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_tower/power_tower_3_',
    },
    {
        annotationType: 'Point',
        category: 'Transmission Tower',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/power_tower/power_tower_27_',
    },
    {
        annotationType: 'Polygon',
        category: 'Quarry',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry/quarry_22_',
    },
    {
        annotationType: 'Polygon',
        category: 'Quarry',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry/quarry_72_',
    },
    {
        annotationType: 'Polygon',
        category: 'Quarry',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry/quarry_26_',
    },
    {
        annotationType: 'Polygon',
        category: 'Quarry',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry/quarry_19_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Clay',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_clay/quarry_resource_clay_4_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Clay',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_clay/quarry_resource_clay_23_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Clay',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_clay/quarry_resource_clay_7_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Clay',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_clay/quarry_resource_clay_17_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Coal',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_coal/quarry_resource_coal_2_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Coal',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_coal/quarry_resource_coal_7_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Coal',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_coal/quarry_resource_coal_12_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Coal',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_coal/quarry_resource_coal_10_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Gravel',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_gravel/quarry_resource_gravel_22_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Gravel',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_gravel/quarry_resource_gravel_27_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Gravel',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_gravel/quarry_resource_gravel_15_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Gravel',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_gravel/quarry_resource_gravel_18_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Peat',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_peat/quarry_resource_peat_5_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Peat',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_peat/quarry_resource_peat_1_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Peat',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_peat/quarry_resource_peat_9_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Peat',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_peat/quarry_resource_peat_16_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Sand',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_sand/quarry_resource_sand_1_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Sand',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_sand/quarry_resource_sand_3_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Sand',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_sand/quarry_resource_sand_13_',
    },
    {
        annotationType: 'Property',
        category: 'Quarry - Resource',
        label: 'Sand',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/quarry_resource_sand/quarry_resource_sand_5_',
    },
    {
        annotationType: 'Polyline',
        category: 'Raceway',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/raceway/raceway_7_',
    },
    {
        annotationType: 'Polyline',
        category: 'Raceway',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/raceway/raceway_18_',
    },
    {
        annotationType: 'Polyline',
        category: 'Raceway',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/raceway/raceway_19_',
    },
    {
        annotationType: 'Polyline',
        category: 'Raceway',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/raceway/raceway_29_',
    },
    {
        annotationType: 'Polyline',
        category: 'Railway',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/railway/railway_6_',
    },
    {
        annotationType: 'Polyline',
        category: 'Railway',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/railway/railway_26_',
    },
    {
        annotationType: 'Polyline',
        category: 'Railway',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/railway/railway_3_',
    },
    {
        annotationType: 'Polyline',
        category: 'Railway',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/railway/railway_18_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rice',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rice/rice_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rice',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rice/rice_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rice',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rice/rice_8_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rice',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rice/rice_14_',
    },
    {
        annotationType: 'Polyline',
        category: 'River',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/river/river_83_',
    },
    {
        annotationType: 'Polyline',
        category: 'River',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/river/river_161_',
    },
    {
        annotationType: 'Polyline',
        category: 'River',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/river/river_4_',
    },
    {
        annotationType: 'Polyline',
        category: 'River',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/river/river_220_',
    },
    {
        annotationType: 'Polyline',
        category: 'Road',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road/road_20_',
    },
    {
        annotationType: 'Polyline',
        category: 'Road',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road/road_25_',
    },
    {
        annotationType: 'Polyline',
        category: 'Road',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road/road_5_',
    },
    {
        annotationType: 'Polyline',
        category: 'Road',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road/road_7_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Bridge',
        label: 'Bridge Present',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_bridge/road_bridge_1_0_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Bridge',
        label: 'Bridge Present',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_bridge/road_bridge_1_5_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Bridge',
        label: 'Bridge Present',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_bridge/road_bridge_1_7_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Bridge',
        label: 'Bridge Present',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_bridge/road_bridge_1_3_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Number of Lanes',
        label: '1',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_lanes_1/road_lanes_1_45_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Number of Lanes',
        label: '2',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_lanes_2/road_lanes_2_62_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Number of Lanes',
        label: '3',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_lanes_3/road_lanes_3_98_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Number of Lanes',
        label: '5',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_lanes_5/road_lanes_5_6_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Speed Limit',
        label: '32 km/h',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_max_speed_32/road_max_speed_32_4_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Speed Limit',
        label: '40 km/h',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_max_speed_40/road_max_speed_40_115_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Speed Limit',
        label: '72 km/h',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_max_speed_72/road_max_speed_72_25_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Speed Limit',
        label: '112 km/h',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_max_speed_112/road_max_speed_112_111_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Motorway',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_motorway/road_road_type_motorway_19_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Motorway',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_motorway/road_road_type_motorway_36_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Motorway',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_motorway/road_road_type_motorway_26_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Motorway',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_motorway/road_road_type_motorway_11_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Pedestrian',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_pedestrian/road_road_type_pedestrian_10_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Pedestrian',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_pedestrian/road_road_type_pedestrian_19_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Pedestrian',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_pedestrian/road_road_type_pedestrian_18_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Pedestrian',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_pedestrian/road_road_type_pedestrian_29_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Primary',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_primary/road_road_type_primary_19_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Primary',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_primary/road_road_type_primary_18_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Primary',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_primary/road_road_type_primary_10_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Primary',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_primary/road_road_type_primary_34_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Residential',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_residential/road_road_type_residential_17_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Residential',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_residential/road_road_type_residential_6_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Residential',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_residential/road_road_type_residential_1_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Residential',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_residential/road_road_type_residential_20_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Secondary',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_secondary/road_road_type_secondary_15_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Secondary',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_secondary/road_road_type_secondary_11_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Secondary',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_secondary/road_road_type_secondary_9_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Secondary',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_secondary/road_road_type_secondary_18_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Service',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_service/road_road_type_service_7_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Service',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_service/road_road_type_service_8_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Service',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_service/road_road_type_service_10_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Service',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_service/road_road_type_service_14_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Tertiary',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_tertiary/road_road_type_tertiary_14_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Tertiary',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_tertiary/road_road_type_tertiary_5_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Tertiary',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_tertiary/road_road_type_tertiary_0_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Tertiary',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_tertiary/road_road_type_tertiary_1_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Track',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_track/road_road_type_track_5_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Track',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_track/road_road_type_track_2_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Track',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_track/road_road_type_track_11_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Track',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_track/road_road_type_track_6_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Trunk',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_trunk/road_road_type_trunk_19_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Trunk',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_trunk/road_road_type_trunk_5_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Trunk',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_trunk/road_road_type_trunk_13_',
    },
    {
        annotationType: 'Property',
        category: 'Road - Type',
        label: 'Trunk',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/road_road_type_trunk/road_road_type_trunk_0_',
    },
    {
        annotationType: 'Point',
        category: 'Rooftop Solar Panel',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rooftop_solar_panel/rooftop_solar_panel_7_',
    },
    {
        annotationType: 'Point',
        category: 'Rooftop Solar Panel',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rooftop_solar_panel/rooftop_solar_panel_29_',
    },
    {
        annotationType: 'Point',
        category: 'Rooftop Solar Panel',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rooftop_solar_panel/rooftop_solar_panel_47_',
    },
    {
        annotationType: 'Point',
        category: 'Rooftop Solar Panel',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rooftop_solar_panel/rooftop_solar_panel_21_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rye',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rye/rye_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Rye',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/rye/rye_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Shrub',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/shrub/shrub_14_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Shrub',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/shrub/shrub_11_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Shrub',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/shrub/shrub_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Shrub',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/shrub/shrub_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Silo',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/silo/silo_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Silo',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/silo/silo_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Silo',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/silo/silo_8_',
    },
    {
        annotationType: 'Polygon',
        category: 'Silo',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/silo/silo_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Ski Resort',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/ski_resort/ski_resort_30_',
    },
    {
        annotationType: 'Polygon',
        category: 'Ski Resort',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/ski_resort/ski_resort_22_',
    },
    {
        annotationType: 'Polygon',
        category: 'Ski Resort',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/ski_resort/ski_resort_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Ski Resort',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/ski_resort/ski_resort_36_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'None',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_no/smoke_no_1546_3302_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'None',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_no/smoke_no_1442_2832_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'None',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_no/smoke_no_1441_2869_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'None',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_no/smoke_no_1429_3269_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Partial',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_partial/smoke_partial_1398_3207_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Partial',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_partial/smoke_partial_1310_3082_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Partial',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_partial/smoke_partial_1487_2843_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Partial',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_partial/smoke_partial_1300_3099_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Full',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_yes/smoke_yes_1473_2856_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Full',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_yes/smoke_yes_1350_3142_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Full',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_yes/smoke_yes_1447_2894_',
    },
    {
        annotationType: 'Classification',
        category: 'Smoke',
        label: 'Full',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/smoke_yes/smoke_yes_1366_3072_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Snow',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow/snow_26_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Snow',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow/snow_22_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Snow',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow/snow_14_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Snow',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow/snow_31_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'None',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_no/snow_no_2490_3000_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'None',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_no/snow_no_2163_3177_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'None',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_no/snow_no_1737_3261_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'None',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_no/snow_no_5115_3476_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Partial',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_partial/snow_partial_2141_3201_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Partial',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_partial/snow_partial_2140_3201_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Partial',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_partial/snow_partial_2135_3207_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Partial',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_partial/snow_partial_6484_3254_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Full',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_yes/snow_yes_1918_2736_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Full',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_yes/snow_yes_2486_2987_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Full',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_yes/snow_yes_6479_3252_',
    },
    {
        annotationType: 'Classification',
        category: 'Snow',
        label: 'Full',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/snow_yes/snow_yes_2123_3264_',
    },
    {
        annotationType: 'Polygon',
        category: 'Solar Farm',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/solar_farm/solar_farm_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Solar Farm',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/solar_farm/solar_farm_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Solar Farm',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/solar_farm/solar_farm_7_',
    },
    {
        annotationType: 'Polygon',
        category: 'Solar Farm',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/solar_farm/solar_farm_8_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Soy',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/soy/soy_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Soy',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/soy/soy_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Soy',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/soy/soy_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Soy',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/soy/soy_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Stadium',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/stadium/stadium_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Stadium',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/stadium/stadium_11_',
    },
    {
        annotationType: 'Polygon',
        category: 'Stadium',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/stadium/stadium_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Stadium',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/stadium/stadium_14_',
    },
    {
        annotationType: 'Polygon',
        category: 'Storage Tank',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/storage_tank/storage_tank_16_',
    },
    {
        annotationType: 'Polygon',
        category: 'Storage Tank',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/storage_tank/storage_tank_14_',
    },
    {
        annotationType: 'Polygon',
        category: 'Storage Tank',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/storage_tank/storage_tank_8_',
    },
    {
        annotationType: 'Polygon',
        category: 'Storage Tank',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/storage_tank/storage_tank_15_',
    },
    {
        annotationType: 'Point',
        category: 'Street Lamp',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/street_lamp/street_lamp_24_',
    },
    {
        annotationType: 'Point',
        category: 'Street Lamp',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/street_lamp/street_lamp_1_',
    },
    {
        annotationType: 'Point',
        category: 'Street Lamp',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/street_lamp/street_lamp_15_',
    },
    {
        annotationType: 'Point',
        category: 'Street Lamp',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/street_lamp/street_lamp_16_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sugarcane',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sugarcane/sugarcane_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sugarcane',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sugarcane/sugarcane_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sugarcane',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sugarcane/sugarcane_6_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sugarcane',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sugarcane/sugarcane_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sunflower',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sunflower/sunflower_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sunflower',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sunflower/sunflower_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sunflower',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sunflower/sunflower_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Sunflower',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/sunflower/sunflower_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Tea',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tea/tea_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Tea',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tea/tea_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Tea',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tea/tea_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Tea',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tea/tea_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Theme Park',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/theme_park/theme_park_8_',
    },
    {
        annotationType: 'Polygon',
        category: 'Theme Park',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/theme_park/theme_park_12_',
    },
    {
        annotationType: 'Polygon',
        category: 'Theme Park',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/theme_park/theme_park_15_',
    },
    {
        annotationType: 'Polygon',
        category: 'Theme Park',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/theme_park/theme_park_10_',
    },
    {
        annotationType: 'Point',
        category: 'Toll Booth',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/toll_booth/toll_booth_3_',
    },
    {
        annotationType: 'Point',
        category: 'Toll Booth',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/toll_booth/toll_booth_7_',
    },
    {
        annotationType: 'Point',
        category: 'Toll Booth',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/toll_booth/toll_booth_10_',
    },
    {
        annotationType: 'Point',
        category: 'Toll Booth',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/toll_booth/toll_booth_9_',
    },
    {
        annotationType: 'Polygon',
        category: 'Track',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track/track_16_',
    },
    {
        annotationType: 'Polygon',
        category: 'Track',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track/track_14_',
    },
    {
        annotationType: 'Polygon',
        category: 'Track',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track/track_10_',
    },
    {
        annotationType: 'Polygon',
        category: 'Track',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track/track_13_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Cycling',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_cycling/track_sport_cycling_23_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Cycling',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_cycling/track_sport_cycling_1_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Cycling',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_cycling/track_sport_cycling_12_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Cycling',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_cycling/track_sport_cycling_13_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Horse',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_horse/track_sport_horse_22_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Horse',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_horse/track_sport_horse_11_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Horse',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_horse/track_sport_horse_15_',
    },
    {
        annotationType: 'Property',
        category: 'Track - Sport',
        label: 'Horse',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_horse/track_sport_horse_0_',
    },
    {
        annotationType: 'Property',
        category: 'Track - sport',
        label: 'Running',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_running/track_sport_running_0_',
    },
    {
        annotationType: 'Property',
        category: 'Track - sport',
        label: 'Running',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_running/track_sport_running_9_',
    },
    {
        annotationType: 'Property',
        category: 'Track - sport',
        label: 'Running',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_running/track_sport_running_1_',
    },
    {
        annotationType: 'Property',
        category: 'Track - sport',
        label: 'Running',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/track_sport_running/track_sport_running_5_',
    },
    {
        annotationType: 'Point',
        category: 'Traffic Signal',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/traffic_signals/traffic_signals_7_',
    },
    {
        annotationType: 'Point',
        category: 'Traffic Signal',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/traffic_signals/traffic_signals_0_',
    },
    {
        annotationType: 'Point',
        category: 'Traffic Signal',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/traffic_signals/traffic_signals_2_',
    },
    {
        annotationType: 'Point',
        category: 'Traffic Signal',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/traffic_signals/traffic_signals_5_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Tree',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree/tree_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Tree',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree/tree_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Tree',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree/tree_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Tree',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree/tree_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Full',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_full/tree_cover_full_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Full',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_full/tree_cover_full_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Full',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_full/tree_cover_full_1_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Full',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_full/tree_cover_full_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'High',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_high/tree_cover_high_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'High',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_high/tree_cover_high_1_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'High',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_high/tree_cover_high_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'High',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_high/tree_cover_high_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Medium',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_medium/tree_cover_medium_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Medium',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_medium/tree_cover_medium_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Medium',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_medium/tree_cover_medium_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Medium',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_medium/tree_cover_medium_1_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Low',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_low/tree_cover_low_1_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Low',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_low/tree_cover_low_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Low',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_low/tree_cover_low_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Low',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_low/tree_cover_low_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Sparse',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_sparse/tree_cover_sparse_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Sparse',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_sparse/tree_cover_sparse_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Sparse',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_sparse/tree_cover_sparse_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'Sparse',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_sparse/tree_cover_sparse_1_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'None',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_none/tree_cover_none_3_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'None',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_none/tree_cover_none_0_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'None',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_none/tree_cover_none_2_',
    },
    {
        annotationType: 'Regression',
        category: 'Tree Cover',
        label: 'None',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/tree_cover_none/tree_cover_none_1_',
    },
    {
        annotationType: 'Point',
        category: 'Vessel',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/vessel/vessel_4_',
    },
    {
        annotationType: 'Point',
        category: 'Vessel',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/vessel/vessel_16_',
    },
    {
        annotationType: 'Point',
        category: 'Vessel',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/vessel/vessel_22_',
    },
    {
        annotationType: 'Point',
        category: 'Vessel',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/vessel/vessel_1_',
    },
    {
        annotationType: 'Polygon',
        category: 'Wastewater Plant',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wastewater_plant/wastewater_plant_5_',
    },
    {
        annotationType: 'Polygon',
        category: 'Wastewater Plant',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wastewater_plant/wastewater_plant_3_',
    },
    {
        annotationType: 'Polygon',
        category: 'Wastewater Plant',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wastewater_plant/wastewater_plant_6_',
    },
    {
        annotationType: 'Polygon',
        category: 'Wastewater Plant',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wastewater_plant/wastewater_plant_7_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Water',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water/water_0_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Water',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water/water_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Water',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water/water_11_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Water',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water/water_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Coastline',
        label: 'Water',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_event/water_event_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Coastline',
        label: 'Water',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_event/water_event_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Coastline',
        label: 'Water',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_event/water_event_6_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Coastline',
        label: 'Water',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_event/water_event_1_',
    },
    {
        annotationType: 'Point',
        category: 'Water Tower',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_tower/water_tower_4_',
    },
    {
        annotationType: 'Point',
        category: 'Water Tower',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_tower/water_tower_0_',
    },
    {
        annotationType: 'Point',
        category: 'Water Tower',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_tower/water_tower_5_',
    },
    {
        annotationType: 'Point',
        category: 'Water Tower',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/water_tower/water_tower_2_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Wetland',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wetland/wetland_10_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Wetland',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wetland/wetland_17_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Wetland',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wetland/wetland_15_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Land Cover',
        label: 'Wetland',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wetland/wetland_23_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Wheat',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wheat/wheat_4_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Wheat',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wheat/wheat_1_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Wheat',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wheat/wheat_3_',
    },
    {
        annotationType: 'Segmentation',
        category: 'Crop',
        label: 'Wheat',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wheat/wheat_2_',
    },
    {
        annotationType: 'Point',
        category: 'Wind Turbine',
        label: '',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine/wind_turbine_38_',
    },
    {
        annotationType: 'Point',
        category: 'Wind Turbine',
        label: '',
        exampleNumber: 1,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine/wind_turbine_34_',
    },
    {
        annotationType: 'Point',
        category: 'Wind Turbine',
        label: '',
        exampleNumber: 2,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine/wind_turbine_1_',
    },
    {
        annotationType: 'Point',
        category: 'Wind Turbine',
        label: '',
        exampleNumber: 3,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine/wind_turbine_78_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Power Output',
        label: '0.66 MW',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_power_mw_0.66/wind_turbine_power_mw_0.66_7_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Power Output',
        label: '0.108 MW',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_power_mw_0.108/wind_turbine_power_mw_0.108_91_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Power Output',
        label: '1.5 MW',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_power_mw_1.5/wind_turbine_power_mw_1.5_2_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Power Output',
        label: '2.3 MW',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_power_mw_2.3/wind_turbine_power_mw_2.3_92_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Rotor Diameter',
        label: '48',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_rotor_diameter_48/wind_turbine_rotor_diameter_48_77_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Rotor Diameter',
        label: '58',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_rotor_diameter_58/wind_turbine_rotor_diameter_58_23_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Rotor Diameter',
        label: '90',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_rotor_diameter_90/wind_turbine_rotor_diameter_90_71_',
    },
    {
        annotationType: 'Property',
        category: 'Wind Turbine - Rotor Diameter',
        label: '108',
        exampleNumber: 0,
        folderUrl:
            'https://storage.googleapis.com/satlas-demo-examples/satlas_website_examples/wind_turbine_rotor_diameter_108/wind_turbine_rotor_diameter_108_94_',
    },
];

const data = originalData.map((d) => {
    return {
        annotationType: d.annotationType,
        category: d.category,
        label: d.label,
        exampleNumber: d.exampleNumber,
        combinedLabel: d.label
            ? `${d.label} - Example ${d.exampleNumber + 1}`
            : `Example ${d.exampleNumber + 1}`,
        folderUrl: d.folderUrl,
    };
});

// convert data into tree format
type TreeValueDict = { [key: string]: { value: string; children: TreeValueDict } };
const tryAdd = (key: string, dict: TreeValueDict) => {
    if (!dict[key]) {
        dict[key] = { value: key, children: {} };
    }
};
export const dataTreeDict: TreeValueDict = {};
data.forEach((d) => {
    tryAdd(d.annotationType, dataTreeDict);
    tryAdd(d.category, dataTreeDict[d.annotationType].children);
    tryAdd(d.combinedLabel, dataTreeDict[d.annotationType].children[d.category].children);
    dataTreeDict[d.annotationType].children[d.category].children[d.combinedLabel].value =
        d.folderUrl;
});
