import React from 'react';
import styled, { keyframes } from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

interface Props {
    images: { src: string; alt: string }[];
    width?: string;
    height?: string;
    speed?: number;
}
export const CrossFadeImageSet = ({ images, width, height, speed }: Props) => {
    return (
        <CrossFade width={width || '400px'} height={height || 'unset'} speed={speed || 3}>
            <Overlay0>
                <FadeImage src={images[0].src} alt={images[0].alt} />
                <AltText index={0}>{images[0].alt}</AltText>
            </Overlay0>
            <Overlay1>
                {' '}
                <FadeImage src={images[1].src} alt={images[1].alt} />
                <AltText index={1}>{images[1].alt}</AltText>
            </Overlay1>
            <Overlay2>
                <FadeImage src={images[2].src} alt={images[2].alt} />
                <AltText index={2}>{images[2].alt}</AltText>
            </Overlay2>
            <Overlay3>
                <FadeImage src={images[3].src} alt={images[3].alt} />
                <AltText index={3}>{images[3].alt}</AltText>
            </Overlay3>
            <Overlay4>
                <FadeImage src={images[4].src} alt={images[4].alt} />
                <AltText index={4}>{images[4].alt}</AltText>
            </Overlay4>
            <Overlay5>
                <FadeImage src={images[5].src} alt={images[5].alt} />
                <AltText index={5}>{images[5].alt}</AltText>
            </Overlay5>
        </CrossFade>
    );
};

const FadeFrames = keyframes`
  0% {
    opacity:1;
  }
  8% {
    opacity:1;
  }
  13% {
    opacity:0;
  }
  87% {
    opacity:0;
  }
  92% {
    opacity:1;
  }
  100% {
    opacity:1;
  }
`;

const Overlay = styled.div`
    animation-name: ${FadeFrames};
    animation-timing-function: step-end;
    animation-iteration-count: infinite;
`;
const Overlay0 = styled(Overlay)``;
const Overlay1 = styled(Overlay)``;
const Overlay2 = styled(Overlay)``;
const Overlay3 = styled(Overlay)``;
const Overlay4 = styled(Overlay)``;
const Overlay5 = styled(Overlay)``;

const AltText = styled.div<{ index: number }>`
    position: absolute;
    top: ${({ index }) => (index + 5) * 36}px;
    left: 24px;
    color: white;
    font-size: 36px;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        font-size: 24px;
        top: ${({ index }) => (index + 8) * 24}px;
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        font-size: 16px;
        top: ${({ index }) => (index + 11) * 16}px;
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.xs)} {
        font-size: 16px;
        top: ${({ index }) => (index + 4) * 16}px;
    }
`;

const FadeImage = styled.img`
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
`;

const CrossFade = styled.div<{ width: string; height: string; speed: number }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    max-height: 100vw;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    ${Overlay0}, ${Overlay1}, ${Overlay2}, ${Overlay3}, ${Overlay4}, ${Overlay5} {
        transition: opacity ${({ speed }) => speed}s ease-in-out;
        animation-duration: ${({ speed }) => speed}s;
    }
    ${Overlay0} {
        animation-delay: ${({ speed }) => -speed * 0.83}s;
    }
    ${Overlay1} {
        animation-delay: ${({ speed }) => -speed * 0.66}s;
    }
    ${Overlay2} {
        animation-delay: ${({ speed }) => -speed * 0.5}s;
    }
    ${Overlay3} {
        animation-delay: ${({ speed }) => -speed * 0.33}s;
    }
    ${Overlay4} {
        animation-delay: ${({ speed }) => -speed * 0.17}s;
    }
    ${Overlay5} {
        animation-delay: ${({ speed }) => -speed * 0.0}s;
    }
`;
