import React, { useState, useEffect } from 'react';
import { Button, Empty, TreeSelect, Select, Collapse } from 'antd';
import styled from 'styled-components';
import { WarningOutlined, CaretRightFilled } from '@ant-design/icons';
import { belowOrEqualTo } from '@allenai/varnish';

import { dataTreeDict, getRandomExample } from '../data/examples';
import { SubHeader, SmallDivP, DivP, Resposive2Col } from './shared';
import { exampleFetch, ExampleFetchData } from './ExampleFetch';
import { CrossFadeImage } from './CrossFadeImage';

export const Examples2 = () => {
    const [exampleFetchData, setExampleFetchData] = useState<ExampleFetchData>();
    const [selectedValue, setSelectedValue] = useState<string>();
    const [selectedParentInfoValue, setSelectedParentInfoValue] = useState<ParentInfo>();
    const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState<string[]>([]);
    const [dataTree, setDataTree] = useState<TreeValue[]>([]);
    const [filteredDataTree, setFilteredDataTree] = useState<TreeValue[]>();

    useEffect(() => {
        // get all data
        const allData = getDataTree();
        // get annotations form all data
        const aTypes = Object.values(allData).map((v) => v.value);
        setDataTree(allData);
        setSelectedAnnotationTypes(aTypes);
        // filter examples to selected annotation types (all on initial load)
        const filteredData = allData.filter((d) => aTypes.includes(d.value));
        setFilteredDataTree(filteredData);
    }, []);

    const onFindItClick = (value: string, row: Partial<TreeValue>) => {
        setSelectedValue(value);
        exampleFetch(value, row.parent?.annotationType).then((res) => setExampleFetchData(res));
        setSelectedParentInfoValue(row?.parent);
    };

    const onSurpriseMeClick = (annotationTypes: string[]) => {
        // get a random example that matches filter, else null thigns out
        const ex = getRandomExample(annotationTypes);
        if (ex) {
            const parent = {
                annotationType: ex.annotationType,
                category: ex.category,
            };
            onFindItClick(ex.folderUrl, { parent: parent });
            setSelectedParentInfoValue(parent);
        } else {
            setSelectedValue(undefined);
            setSelectedParentInfoValue(undefined);
        }
    };

    const onAnnotationTypesChange = (values: string[]) => {
        setSelectedAnnotationTypes(values);
        const filteredValues = dataTree.filter((d) => values.includes(d.value));
        setFilteredDataTree(filteredValues);
        // if our current example does not have an annotation type currently selected, go get a new example
        if (!selectedParentInfoValue || !values.includes(selectedParentInfoValue.annotationType)) {
            onSurpriseMeClick(values);
        }
    };

    type ParentInfo = { annotationType: string; category: string };
    type TreeValue = {
        value: string;
        title: string | JSX.Element;
        titleWithPath?: string | JSX.Element;
        parent?: ParentInfo;
        selectable?: boolean;
        isLeaf?: boolean;
        children?: TreeValue[];
    };

    const getDataTree = (): TreeValue[] => {
        return Object.values(dataTreeDict).map((annotationType) => {
            return {
                value: annotationType.value,
                title: <UnselectableTreeLabel>{annotationType.value}</UnselectableTreeLabel>,
                selectable: false,
                children: Object.values(annotationType.children).map((category) => {
                    return {
                        value: category.value,
                        title: <UnselectableTreeLabel>{category.value}</UnselectableTreeLabel>,
                        selectable: false,
                        children: Object.entries(category.children).map(
                            ([combinedLabelAsKey, folderUrl]) => {
                                return {
                                    value: folderUrl.value,
                                    title: combinedLabelAsKey,
                                    titleWithPath: (
                                        <span>
                                            {annotationType.value} <CaretRightFilled />{' '}
                                            {category.value} <CaretRightFilled />{' '}
                                            {combinedLabelAsKey}
                                        </span>
                                    ),
                                    isLeaf: true,
                                    parent: {
                                        annotationType: annotationType.value,
                                        category: category.value,
                                    },
                                };
                            }
                        ),
                    };
                }),
            };
        });
    };

    const SurpriseMe = () => {
        return (
            <span>
                Out of ideas?{' '}
                <SmallButton type="link" onClick={() => onSurpriseMeClick(selectedAnnotationTypes)}>
                    Surprise me.
                </SmallButton>
            </span>
        );
    };

    return (
        <OuterContainer>
            <Container>
                <SubHeader id="examples">Example Gallery</SubHeader>

                <p>
                    Explore examples of the annotated images available in the SatlasPretrain
                    dataset.
                </p>
                <Resposive2Col>
                    <div>
                        <p>
                            <SurpriseMe />
                        </p>
                        <DivP>
                            <SmallDivP>Select an Example</SmallDivP>
                            <FullWidthTreeSelect
                                treeLine
                                filterTreeNode
                                showSearch
                                treeData={filteredDataTree}
                                value={selectedValue}
                                treeNodeLabelProp="titleWithPath"
                                placeholder="Start typing..."
                                onSelect={onFindItClick}
                            />
                        </DivP>
                        <Collapse ghost>
                            <CollapsePanel header="Advanced Filters" key="1">
                                <SmallDivP>Showing examples with annotation types:</SmallDivP>
                                <FullWidthSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Please select one or more..."
                                    value={selectedAnnotationTypes}
                                    onChange={onAnnotationTypesChange}
                                    options={Object.keys(dataTreeDict).map((v) => {
                                        return { value: v };
                                    })}
                                />
                            </CollapsePanel>
                        </Collapse>
                    </div>
                    {exampleFetchData ? (
                        <div>
                            {exampleFetchData.errors.length ? (
                                <BorderedEmpty
                                    description={
                                        <span>
                                            Sorry, there has been an error. How did you do that?
                                            Maybe you should{' '}
                                            <a
                                                href="https://allenai.org/careers#current-openings-ai2"
                                                target="_blank"
                                                rel="noreferrer noopener">
                                                come work with us at AI2
                                            </a>
                                            .
                                        </span>
                                    }
                                    image={<WarningOutlined />}
                                    imageStyle={{
                                        height: '250px',
                                        fontSize: '200px',
                                    }}></BorderedEmpty>
                            ) : (
                                <div>
                                    <SmallDivP>
                                        <a
                                            href={`https://maps.google.com/?q=${exampleFetchData.lat},${exampleFetchData.lon}`}
                                            target="_blank"
                                            rel="noreferrer noopener">
                                            (
                                            {exampleFetchData.lat.toLocaleString(undefined, {
                                                minimumFractionDigits: 6,
                                                maximumFractionDigits: 6,
                                            })}
                                            ,{' '}
                                            {exampleFetchData.lon.toLocaleString(undefined, {
                                                minimumFractionDigits: 6,
                                                maximumFractionDigits: 6,
                                            })}
                                            )
                                        </a>
                                    </SmallDivP>
                                    {selectedParentInfoValue &&
                                    (selectedParentInfoValue.annotationType === 'Segmentation' ||
                                        (selectedParentInfoValue.annotationType === 'Regression' &&
                                            selectedParentInfoValue.category ===
                                                'Elevation and Bathymetry')) ? (
                                        <CrossFadeImage
                                            inSrc={exampleFetchData.img1Src}
                                            inAlt="Example"
                                            outSrc={exampleFetchData.img2Src}
                                            outAlt="Example overlay"
                                            width="100%"
                                            speed="1.5s"
                                        />
                                    ) : (
                                        <img
                                            src={exampleFetchData.img2Src}
                                            alt="Example"
                                            width="100%"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <BorderedEmpty
                            description={
                                <span>
                                    Please select an example. <SurpriseMe />
                                </span>
                            }
                            imageStyle={{
                                height: '250px',
                            }}></BorderedEmpty>
                    )}
                </Resposive2Col>
            </Container>
        </OuterContainer>
    );
};

const SmallButton = styled(Button)`
    padding: 0;
`;

const BorderedEmpty = styled(Empty)`
    border: 1px dotted ${({ theme }) => theme.color.N5};
    padding: ${({ theme }) => theme.spacing.md};
`;

const UnselectableTreeLabel = styled.div`
    color: ${({ theme }) => theme.color.N7};
`;

const FullWidthTreeSelect = styled(TreeSelect)`
    width: 100%;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        width: calc(100vw - 75px);
    }
` as typeof TreeSelect;

const FullWidthSelect = styled(Select)`
    && {
        width: 100%;

        @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
            width: calc(100vw - 75px);
        }
    }
` as typeof Select;

const CollapsePanel = styled(Collapse.Panel)`
    && {
        .ant-collapse-header {
            padding: 0;
        }
    }
` as typeof Collapse.Panel;

const OuterContainer = styled.div`
    background: ${({ theme }) => theme.color.B1};
    margin: 27px -100% 12px -100%;
    padding: 1px 0 50px 0;
`;

const Container = styled.div`
    max-width: 1140px;
    width: calc(100vw - 66px);
    margin: auto;
`;
