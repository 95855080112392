import React from 'react';
import styled from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish/lib';

import { SubHeader, Resposive2Col } from './shared';
import { CrossFadeImageSet } from './CrossFadeImageSet';
import collageSrc from '../images/collage_glow.png';
import scaleSrc from '../images/scale_1000w.jpg';
import tl2016bSrc from '../images/2016-08-07b.jpg';
import tl2017bSrc from '../images/2017-08-07b.jpg';
import tl2018bSrc from '../images/2018-08-02b.jpg';
import tl2019bSrc from '../images/2019-08-02b.jpg';
import tl2020bSrc from '../images/2020-08-26b.jpg';
import tl2021bSrc from '../images/2021-08-01b.jpg';

export const HowItsDifferent = () => {
    return (
        <React.Fragment>
            <SubHeader>How SatlasPretrain is Different</SubHeader>

            <Resposive2Col>
                <Description>
                    <h4>Scale</h4>
                    <p>
                        SatlasPretrain covers 50x more of the Earth’s surface than the largest
                        existing dataset (
                        <a
                            href="https://github.com/fMoW/dataset"
                            target="_blank"
                            rel="noreferrer noopener">
                            FMoW
                        </a>
                        ).
                    </p>
                </Description>
                <Image
                    alt="Map of the world highlighting Satlas’ coverage areas. Satlas covers 50% more than the largest existing dataset"
                    src={scaleSrc}
                />
            </Resposive2Col>

            <Resposive2Col flop>
                <Description>
                    <h4>Large diversity of labels</h4>
                    <p>
                        Existing satellite image datasets focus only on one mode of annotation,
                        e.g., classification or object detection. SatlasPretrain labels span seven
                        different annotation modes and 137 categories, twice as many as the previous
                        largest existing dataset.
                    </p>
                </Description>
                <Image
                    alt="A comparison of Satlas’ annotation capabilities. One image shows a large polygon annotation and the other image shows many, small polygon annotations"
                    src={collageSrc}
                />
            </Resposive2Col>

            <h4>Time and space</h4>
            <p>
                Rather than being tied to individual satellite images, SatlasPretrain labels are
                computed at specific geographic coordinates over long timespans. This allows
                SatlasPretrain to highlight events such as water loss in reservoirs and
                deforestation which cannot otherwise be identified from a single moment in time.
            </p>
            <CrossFadeImageSet
                images={[
                    { src: tl2016bSrc, alt: '7 August 2016' },
                    { src: tl2017bSrc, alt: '7 August 2017' },
                    { src: tl2018bSrc, alt: '2 August 2018' },
                    { src: tl2019bSrc, alt: '2 August 2019' },
                    { src: tl2020bSrc, alt: '26 August 2020' },
                    { src: tl2021bSrc, alt: '1 August 2021' },
                ]}
                width="100%"
                height="650px"
                speed={13}
            />
        </React.Fragment>
    );
};

const Description = styled.div`
    align-self: center;
    h4 {
        margin-top: 0;
        @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
            margin-top: revert;
        }
    }
`;

const Image = styled.img`
    width: 100%;
`;
